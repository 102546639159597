import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import Router from './routes';

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Poppins"',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: {
        main: '#0D52AC',
        dark: '#0049A8',
      },
      success: {
        light: '#2ECC71',
        main: '#03C04A',
        dark: '#008000',
      },
      danger: {
        main: '#FB4141',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {/* <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content="default-src *; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval' https://api-staging.baleomol.com"
        />
      </Helmet> */}
      <div id="affiliate-baleomol">
        <Router />
      </div>
    </ThemeProvider>
  );
};

export default App;
