/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoaderTriangle from '../components/organisms/LoaderTriangle';

const ResendActivation = lazy(() =>
  import('../pages/Activation/ResendActivation'),
);
const Referrals = lazy(() => import('../pages/Referrals'));
const Activation = lazy(() => import('../pages/Activation'));
const LandingPage = lazy(() => import('../pages/LandingPage'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const MainPage = lazy(() => import('../pages/MainPage'));
const Cart = lazy(() => import('../pages/v2/Cart'));
const Checkout = lazy(() => import('../pages/v3/Checkout'));
const Categories = lazy(() => import('../pages/Categories'));
const Order = lazy(() => import('../pages/Order'));
const Payment = lazy(() => import('../pages/Payment'));
const ProductListOnCategory = lazy(() =>
  import('../pages/ProductListOnCategory'),
);
const Search = lazy(() => import('../pages/Search'));
const ProductDetail = lazy(() => import('../pages/ProductDetail'));
const ProductReview = lazy(() => import('../pages/ProductReview'));
const Chat = lazy(() => import('../pages/Chat'));
const Transaction = lazy(() => import('../pages/Transaction'));
const TransactionDetail = lazy(() => import('../pages/TransactionDetail'));
const Settlement = lazy(() => import('../pages/Settlement'));

const Router = () => {
  const exact = true;
  return (
    <Suspense fallback={<LoaderTriangle />}>
      <Routes>
        <Route exact={exact} path="*" element={<ErrorPage />} />
        <Route exact={exact} path="/error" element={<ErrorPage />} />
        <Route exact={exact} path="/" element={<LandingPage />} />
        <Route exact={exact} path="/home" element={<LandingPage />} />
        <Route exact={exact} path="/referrals" element={<Referrals />} />
        <Route exact={exact} path="/activation" element={<Activation />} />
        <Route
          exact={exact}
          path="/resend-activation"
          element={<ResendActivation />}
        />
        <Route exact={exact} path="/:affiliateId/*" element={<ErrorPage />} />
        <Route exact={exact} path="/:affiliateId" element={<MainPage />} />
        <Route exact={exact} path="/:affiliateId/order" element={<Order />} />
        <Route
          exact={exact}
          path="/:affiliateId/order/*"
          element={<ErrorPage />}
        />
        <Route exact={exact} path="/:affiliateId/cart" element={<Cart />} />
        <Route
          exact={exact}
          path="/:affiliateId/cart/*"
          element={<ErrorPage />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/checkout"
          element={<Checkout />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/checkout/*"
          element={<ErrorPage />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/product/:productId"
          element={<ProductDetail />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/product/:productId/reviews"
          element={<ProductReview />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/product/:productId/*"
          element={<ErrorPage />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/category"
          element={<Categories />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/category/:categoryId"
          element={<ProductListOnCategory />}
        />
        <Route exact={exact} path="/:affiliateId/search" element={<Search />} />
        <Route
          exact={exact}
          path="/:affiliateId/categories/*"
          element={<ErrorPage />}
        />
        <Route exact={exact} path="/payment" element={<Payment />} />
        <Route exact={exact} path="/payment/*" element={<ErrorPage />} />
        <Route exact={exact} path="/:affiliateId/chat" element={<Chat />} />
        <Route
          exact={exact}
          path="/:affiliateId/transaction"
          element={<Transaction />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/transaction/detail"
          element={<TransactionDetail />}
        />
        <Route
          exact={exact}
          path="/:affiliateId/settlement/:invoiceId"
          element={<Settlement />}
        />
      </Routes>
    </Suspense>
  );
};

export default Router;
