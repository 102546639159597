import React, { useEffect } from 'react';
import './style.scss';

const LoaderTriangle = () => {
  useEffect(() => {}, []);

  return (
    <div id="loader__triangle">
      <div className="loader-triangle-3">
        <svg id="triangle" viewBox="-3 -4 39 39">
          <polygon points="16,0 32,32 0,32" />
        </svg>
      </div>
    </div>
  );
};

export default LoaderTriangle;
